import IconWithTooltip from "components/common/IconWithTooltip";
import ChargeCardCashBackInfoIconWithTooltip from "resources/capital-accounts/components/ChargeCardCashBackInfoIconWithTooltip";
import { CapitalAccountSummaryWithChargeCard } from "resources/capital-accounts/utils/isCapitalAccountSummaryWithChargeCard";
import ChargeCardOfferRepaymentPeriodContent from "resources/charge-cards/components/ChargeCardOfferRepaymentPeriodContent";
import StatementCycleExplanation from "resources/charge-cards/components/StatementCycleExplanation";
import useChargeCardOffer from "resources/charge-cards/queries/useChargeCardOffer";
import CashDisplay from "ui/data-display/money/CashDisplay";
import SummaryArray from "ui/data-display/SummaryArray";

type Props = {
  capitalAccountSummaryWithChargeCard: CapitalAccountSummaryWithChargeCard;
};

const ChargeCardSummaryItems = ({ capitalAccountSummaryWithChargeCard }: Props) => {
  const chargeCardOffer = useChargeCardOffer(capitalAccountSummaryWithChargeCard.guid, {
    required: true,
  });

  return (
    <SummaryArray className="w-full bg-white">
      <SummaryArray.Item>
        <SummaryArray.Heading>Limit</SummaryArray.Heading>
        <CashDisplay cents={capitalAccountSummaryWithChargeCard.details.limit} />
      </SummaryArray.Item>

      {chargeCardOffer.maxCashback > 0 && (
        <SummaryArray.Item>
          <SummaryArray.Heading>
            Cash back
            <ChargeCardCashBackInfoIconWithTooltip chargeCardOffer={chargeCardOffer} />
          </SummaryArray.Heading>
          <SummaryArray.Value>Up to {chargeCardOffer.maxCashback}%</SummaryArray.Value>
        </SummaryArray.Item>
      )}

      <SummaryArray.Item>
        <SummaryArray.Heading>
          Repayment period
          <IconWithTooltip
            color="dark"
            tooltip={<StatementCycleExplanation creditTerms={chargeCardOffer.creditTerms} />}
          />
        </SummaryArray.Heading>
        <SummaryArray.Value>
          <ChargeCardOfferRepaymentPeriodContent
            capitalAccountSummaryWithChargeCard={capitalAccountSummaryWithChargeCard}
          />
        </SummaryArray.Value>
      </SummaryArray.Item>
    </SummaryArray>
  );
};

export default ChargeCardSummaryItems;
