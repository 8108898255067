import { FC } from "react";
import { Controller } from "react-hook-form";
import CapitalAccountRep from "reps/CapitalAccountRep";
import { checkIsActiveCapitalAccountWithChargeCard } from "resources/capital-accounts/hooks/useActiveCapitalAccountsWithChargeCard";
import { NonTerminatedCapitalAccount } from "resources/capital-accounts/hooks/useNonTerminatedCapitalAccounts";
import useNonTerminatedCapitalAccountsWithChargeCard from "resources/capital-accounts/hooks/useNonTerminatedCapitalAccountsWithChargeCard";
import CardTypeRadioCard from "resources/cards/components/CardTypeRadioCard";
import { CardCreditOrDebit, CardVirtualOrPhysical } from "resources/cards/types";
import getCardTypeByAttributes from "resources/cards/utils/getCardTypeByAttributes";
import chargeCardAccountQueryHooks from "resources/charge-cards/queries/chargeCardAccountQueryHooks";
import useChargeCardOffer from "resources/charge-cards/queries/useChargeCardOffer";
import RadioCardSelect from "ui/inputs/RadioCardSelect";
import SegmentControls from "ui/navigation/SegmentControls";
import ItemWithTooltip from "ui/overlay/ItemWithTooltip";
import ModalV4 from "ui/overlay/ModalV4";
import { Paragraph } from "ui/typography";
import { FormSubmitHandlerProps } from "utils/react-hook-form/GetUseFormSubmitHandler";
import { TabConfig } from "utils/tabs/useTabState";

import useCreateCardModalSelectCardTypeForm, {
  CreateCardModalSelectCardTypeFormOutputs,
  CreditOrDebitOption,
} from "./useCreateCardModalSelectCardTypeForm";

type ChargeCardOfferCreditDescriptionProps = {
  capitalAccountGuid: string;
  checked: boolean;
};

const ChargeCardOfferCreditDescription: FC<ChargeCardOfferCreditDescriptionProps> = ({
  capitalAccountGuid,
  checked,
}) => {
  const chargeCardOffer = useChargeCardOffer(capitalAccountGuid, { required: true });

  return (
    <CardTypeRadioCard.CreditDescription
      checked={checked}
      chargeCardMaxCashback={chargeCardOffer.maxCashback}
      maxRepaymentDays={chargeCardOffer.maxRepaymentDays}
    />
  );
};

type ActiveChargeCardAccountCreditDescriptionProps = {
  capitalAccountGuid: string;
  checked: boolean;
};

const ActiveChargeCardAccountCreditDescription: FC<
  ActiveChargeCardAccountCreditDescriptionProps
> = ({ capitalAccountGuid, checked }) => {
  const chargeCardAccount = chargeCardAccountQueryHooks.useDataRequired({
    capitalAccountGuid,
  });

  return (
    <CardTypeRadioCard.CreditDescription
      checked={checked}
      chargeCardMaxCashback={chargeCardAccount.maxCashback}
      maxRepaymentDays={chargeCardAccount.maxRepaymentDays}
    />
  );
};

const TooltipContent: FC<{ state: NonTerminatedCapitalAccount["state"] }> = ({ state }) => {
  switch (state) {
    case CapitalAccountRep.State.Active:
      return null;
    case CapitalAccountRep.State.OfferAccepted:
      return <Paragraph>Please be sure to reroute payments to activate this account.</Paragraph>;
    case CapitalAccountRep.State.Offered:
      return <Paragraph>Please accept the offer to activate this account.</Paragraph>;
  }
};

const tabs: TabConfig<CardVirtualOrPhysical> = {
  virtual: {
    label: "Virtual card",
  },
  physical: {
    label: "Physical card",
  },
};

type Props = FormSubmitHandlerProps<typeof useCreateCardModalSelectCardTypeForm> & {
  defaultCardVirtualOrPhysical: CardVirtualOrPhysical;
  defaultCardCreditOrDebit: CardCreditOrDebit;
  defaultCapitalAccountGuid?: string;
  submittedDataDefaultValues: CreateCardModalSelectCardTypeFormOutputs | null;
};

const CreateCardModalSelectCardTypeForm: FC<Props> = ({
  onValid,
  onInvalid,
  defaultCardVirtualOrPhysical,
  defaultCardCreditOrDebit,
  defaultCapitalAccountGuid,
  submittedDataDefaultValues,
}) => {
  const nonTerminatedCapitalAccountWithChargeCard = useNonTerminatedCapitalAccountsWithChargeCard();

  const defaultCapitalAccountByGuid = nonTerminatedCapitalAccountWithChargeCard.find(
    (capitalAccount) => capitalAccount.guid === defaultCapitalAccountGuid
  );
  const firstActiveCapitalAccountWithChargeCard = nonTerminatedCapitalAccountWithChargeCard.find(
    checkIsActiveCapitalAccountWithChargeCard
  );

  const form = useCreateCardModalSelectCardTypeForm({
    defaultValues: {
      virtualOrPhysical: defaultCardVirtualOrPhysical,
      creditOrDebitOption:
        // Set default value to the first active capital account, otherwise select "debit"
        defaultCardCreditOrDebit === "credit" && firstActiveCapitalAccountWithChargeCard
          ? {
              type: "credit",
              capitalAccount:
                defaultCapitalAccountByGuid ?? firstActiveCapitalAccountWithChargeCard,
            }
          : { type: "debit" },
      ...submittedDataDefaultValues,
    },
  });

  const selectedVirtualOrPhysical = form.watch("virtualOrPhysical");

  const radioCardCapitalAccountOptions: CreditOrDebitOption[] =
    nonTerminatedCapitalAccountWithChargeCard.map((capitalAccount) => {
      return {
        type: "credit" as const,
        capitalAccount: capitalAccount,
      };
    });

  return (
    <ModalV4.Form onSubmit={form.handleSubmit(onValid, onInvalid)}>
      <ModalV4.Body>
        <Controller
          control={form.control}
          name="virtualOrPhysical"
          render={({ field: { value, onChange } }) => {
            return (
              <SegmentControls
                activeTab={value}
                tabs={tabs}
                setActiveTab={onChange}
                size="md"
                className="mb-6"
              />
            );
          }}
        />

        <Controller
          control={form.control}
          name="creditOrDebitOption"
          render={({ field: { ref: _ref, ...field } }) => {
            return (
              <RadioCardSelect
                options={[...radioCardCapitalAccountOptions, { type: "debit" as const }]}
                {...field}
              >
                {({ option, isSelected, onSelect }) => {
                  const cardType = getCardTypeByAttributes({
                    creditOrDebit: option.type,
                    virtualOrPhysical: selectedVirtualOrPhysical,
                  });

                  if (option.type === "debit") {
                    return (
                      <CardTypeRadioCard
                        key={option.type}
                        value={option.type}
                        checked={isSelected}
                        cardType={cardType}
                        label="Debit card"
                        onChange={() => onSelect(option)}
                        description={({ checked }) => {
                          return <CardTypeRadioCard.DebitDescription checked={checked} />;
                        }}
                      />
                    );
                  } else {
                    const capitalAccountGuid = option.capitalAccount.guid;
                    const capitalAccountState = option.capitalAccount.state;

                    return (
                      <ItemWithTooltip
                        key={`${option.type}-${capitalAccountGuid}`}
                        tooltip={
                          capitalAccountState !== CapitalAccountRep.State.Active && (
                            <TooltipContent state={capitalAccountState} />
                          )
                        }
                        asChild
                      >
                        <CardTypeRadioCard
                          value={`${option.type}-${capitalAccountGuid}`}
                          checked={isSelected}
                          cardType={cardType}
                          label={option.capitalAccount.name}
                          onChange={() => onSelect(option)}
                          disabled={capitalAccountState !== CapitalAccountRep.State.Active}
                          description={({ checked }) => {
                            if (capitalAccountState === CapitalAccountRep.State.Active) {
                              return (
                                <ActiveChargeCardAccountCreditDescription
                                  capitalAccountGuid={capitalAccountGuid}
                                  checked={checked}
                                />
                              );
                            } else {
                              return (
                                <ChargeCardOfferCreditDescription
                                  capitalAccountGuid={capitalAccountGuid}
                                  checked={checked}
                                />
                              );
                            }
                          }}
                        />
                      </ItemWithTooltip>
                    );
                  }
                }}
              </RadioCardSelect>
            );
          }}
        />
      </ModalV4.Body>

      <ModalV4.Footer>
        <ModalV4.SubmitButton>Next</ModalV4.SubmitButton>
        <ModalV4.CloseButton />
      </ModalV4.Footer>
    </ModalV4.Form>
  );
};

export default CreateCardModalSelectCardTypeForm;
