import { ArrowFatLinesUp, CreditCard } from "@phosphor-icons/react";
import dayjs from "dayjs";
import ChangeAutoPayAccountButton from "pages/capital/CapitalAccountPage/components/ChangeAutoPayAccountButton";
import { permissionsTooltipCopy } from "pages/capital/CapitalAccountPage/constants";
import { FC } from "react";
import { withErrorBoundary } from "react-error-boundary";
import { useOpenBankAccountRequired } from "resources/bank-accounts/queries/bankAccountQueryHooks";
import usePrimaryBankAccount from "resources/bank-accounts/queries/usePrimaryBankAccount";
import { CapitalAccountSummaryWithChargeCard } from "resources/capital-accounts/utils/isCapitalAccountSummaryWithChargeCard";
import chargeCardAccountQueryHooks from "resources/charge-cards/queries/chargeCardAccountQueryHooks";
import useChargeCardRepaymentInfo from "resources/charge-cards/queries/useChargeCardRepaymentInfo";
import HighlightCard from "ui/data-display/HighlightCard";
import ButtonLinkWithTooltip from "ui/inputs/ButtonLink/ButtonLinkWithTooltip";
import useIsAllowedToRepayCapital from "utils/permissions/useIsAllowedtoRepayCapital";
import RequiredButNotFoundError from "utils/react-query/RequiredButNotFoundError";

import ChargeCardAutoPayHighlightItem from "./ChargeCardAutoPayHighlightItem";
import ChargeCardCurrentBalanceHighlightItem from "./ChargeCardCurrentBalanceHighlightItem";
import ChargeCardRepaymentHighlightItem from "./ChargeCardRepaymentHighlightItem";

type Props = {
  capitalAccountSummaryWithChargeCard: CapitalAccountSummaryWithChargeCard;
};

const ChargeCardHighlightCard: FC<Props> = ({ capitalAccountSummaryWithChargeCard }) => {
  const chargeCardAccount = chargeCardAccountQueryHooks.useDataRequired({
    capitalAccountGuid: capitalAccountSummaryWithChargeCard.guid,
  });
  const isAllowedToRepayCapital = useIsAllowedToRepayCapital();
  const chargeCardRepaymentInfo = useChargeCardRepaymentInfo(chargeCardAccount.guid);
  const primaryAccount = usePrimaryBankAccount({ required: true });
  const repaymentAccount = useOpenBankAccountRequired(
    chargeCardAccount.repaymentBankAccountGuid ?? primaryAccount.guid
  );

  // TODO(alex): figure out a cleaner way to handle null & loading states
  if (!chargeCardRepaymentInfo) {
    return null;
  }

  return (
    <HighlightCard
      header={
        <HighlightCard.Header icon={<CreditCard weight="light" />}>Card spend</HighlightCard.Header>
      }
      body={
        <>
          <ChargeCardCurrentBalanceHighlightItem
            cardBalanceSettledInCents={0 - capitalAccountSummaryWithChargeCard.cardBalance}
            cardBalancePendingInCents={0 - capitalAccountSummaryWithChargeCard.cardPending}
          />
          <ChargeCardRepaymentHighlightItem
            balanceDueDate={dayjs(chargeCardRepaymentInfo.nextRepaymentDueDate)}
            balanceDueAmountInCents={chargeCardRepaymentInfo.remainingAmountDue}
          />
          <ChargeCardAutoPayHighlightItem
            balanceDueAmountInCents={chargeCardRepaymentInfo.remainingAmountDue}
            autoPayAccount={repaymentAccount}
          />
        </>
      }
      footer={
        <>
          <ButtonLinkWithTooltip
            to={`/capital/${capitalAccountSummaryWithChargeCard.guid}/cards/repay`}
            className="w-full"
            variant="secondary"
            disabled={!isAllowedToRepayCapital}
            tooltip={!isAllowedToRepayCapital && permissionsTooltipCopy.notAllowedToRepayCapital}
          >
            <ArrowFatLinesUp size={20} weight="light" />
            Repay
          </ButtonLinkWithTooltip>

          <ChangeAutoPayAccountButton
            className="w-full"
            capitalAccountSummary={capitalAccountSummaryWithChargeCard}
          />
        </>
      }
    />
  );
};

export default withErrorBoundary(ChargeCardHighlightCard, {
  fallbackRender: ({ error }) => {
    if (error instanceof RequiredButNotFoundError) {
      return null;
    } else {
      throw error;
    }
  },
});
