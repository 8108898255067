import { useMutation } from "@tanstack/react-query";
import useBusinessGuid from "resources/jwt/queries/useBusinessGuid";
import { notify } from "ui/feedback/Toast";
import useHighbeamApi from "utils/customHooks/useHighbeamApi";

import capitalAccountSummaryQueryHooks from "../queries/capitalAccountSummaryQueryHooks";

type Variables = {
  name: string;
};

type Params = {
  capitalAccountGuid: string;
};

const useEditCapitalAccountNameMutation = ({ capitalAccountGuid }: Params) => {
  const highbeamApi = useHighbeamApi();
  const businessGuid = useBusinessGuid();
  const refreshCapitalAccountSummary = capitalAccountSummaryQueryHooks.useRefreshQuery({
    capitalAccountGuid,
  });

  return useMutation({
    mutationFn: async ({ name }: Variables) => {
      return await highbeamApi.capitalAccount.update(businessGuid, capitalAccountGuid, { name });
    },
    onError: () => {
      notify("error", "Something went wrong! Please try again.");
    },
    onSuccess: async () => {
      await refreshCapitalAccountSummary();
      notify("success", "Capital account name updated successfully!");
    },
  });
};

export default useEditCapitalAccountNameMutation;
