import IconWithTooltip from "components/common/IconWithTooltip";
import { FC, ReactNode } from "react";
import ChargeCardAccountRep from "reps/ChargeCardAccountRep";
import StatementCycleExplanation from "resources/charge-cards/components/StatementCycleExplanation";
import chargeCardAccountQueryHooks from "resources/charge-cards/queries/chargeCardAccountQueryHooks";
import getChargeCardRepaymentTermsCopy from "resources/charge-cards/utils/getChargeCardRepaymentTermsCopy";
import MetadataList from "ui/data-display/MetadataList";
import Shimmer from "ui/feedback/ShimmerV2";

type Props = {
  chargeCardAccount: ChargeCardAccountRep.Complete;
  className?: string;
};

const ChargeCardCapitalAccountMetadataList: FC<Props> = ({ chargeCardAccount, className }) => {
  return (
    <MetadataList className={className}>
      <MetadataList.Item>
        <MetadataList.ItemLabel>Repayment terms</MetadataList.ItemLabel>
        <MetadataList.ItemValue>
          {getChargeCardRepaymentTermsCopy(chargeCardAccount.maxRepaymentDays)}
          <IconWithTooltip
            tooltip={<StatementCycleExplanation creditTerms={chargeCardAccount.creditTerms} />}
          />
        </MetadataList.ItemValue>
      </MetadataList.Item>

      {chargeCardAccount && chargeCardAccount.maxCashback > 0 && (
        <MetadataList.Item>
          <MetadataList.ItemLabel>Cash back</MetadataList.ItemLabel>
          <MetadataList.ItemValue>{chargeCardAccount.maxCashback}%</MetadataList.ItemValue>
        </MetadataList.Item>
      )}
    </MetadataList>
  );
};

export default ChargeCardCapitalAccountMetadataList;

const ChargeCardCapitalAccountMetadataListLoading: FC<{ className?: string }> = ({ className }) => {
  return (
    <MetadataList className={className}>
      <MetadataList.Item>
        <MetadataList.ItemLabel>Repayment terms</MetadataList.ItemLabel>
        <MetadataList.ItemValue>
          <Shimmer />
        </MetadataList.ItemValue>
      </MetadataList.Item>
    </MetadataList>
  );
};

// Wrappers

type ChargeCardCapitalAccountMetadataListByCapitalAccountGuidProps = {
  capitalAccountGuid: string;
  notFoundFallback?: ReactNode;
};

export const ChargeCardCapitalAccountMetadataListByCapitalAccountGuid: FC<
  ChargeCardCapitalAccountMetadataListByCapitalAccountGuidProps
> = ({ capitalAccountGuid, notFoundFallback = null }) => {
  const { data: chargeCardAccount, isLoading } = chargeCardAccountQueryHooks.useQuery({
    capitalAccountGuid,
  });

  if (isLoading) {
    return <ChargeCardCapitalAccountMetadataListLoading />;
  }

  if (!chargeCardAccount) {
    return <>{notFoundFallback}</>;
  }

  return <ChargeCardCapitalAccountMetadataList chargeCardAccount={chargeCardAccount} />;
};
