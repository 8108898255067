import { CaretRight, Clock } from "@phosphor-icons/react";
import IconWithTooltip from "components/common/IconWithTooltip";
import dayjs from "dayjs";
import DashboardPage from "layouts/DashboardPage";
import { FC, useState } from "react";
import { ChargeCardAutoPayBankAccountHighlightItemByCapitalAccountGuid } from "resources/capital-accounts/components/ChargeCardAutoPayBankAccountHighlightItem";
import ChargeCardCapitalAccountBarChart from "resources/capital-accounts/components/ChargeCardCapitalAccountBarChart";
import { ChargeCardCapitalAccountMetadataListByCapitalAccountGuid } from "resources/capital-accounts/components/ChargeCardCapitalAccountMetadataList";
import { ChargeCardRepaymentPeriodDateRangeByCapitalAccountGuid } from "resources/capital-accounts/components/ChargeCardRepaymentPeriodDateRange";
import NextAutoPaymentHighlightItem from "resources/capital-accounts/components/NextAutoPaymentHighlightItem";
import EditCapitalAccountAutoPayAccountModal from "resources/capital-accounts/dialogs/EditCapitalAccountAutoPayAccountModal";
import capitalRepaymentAmountsQueryHooks, {
  useNextCapitalAutoPayAmount,
} from "resources/capital-accounts/queries/capitalRepaymentAmountsQueryHooks";
import { CapitalAccountSummaryWithChargeCard } from "resources/capital-accounts/utils/isCapitalAccountSummaryWithChargeCard";
import Divider from "ui/data-display/Divider";
import MoneyAmount from "ui/data-display/money/MoneyAmount";
import MoneyAmountFraction from "ui/data-display/money/MoneyAmountFraction";
import Pill from "ui/data-display/Pill";
import BarChart from "ui/data-visualization/BarChart";
import VirtualButton from "ui/inputs/VirtualButton";
import { Span, Strong } from "ui/typography";

import CapitalAccountAssociatedCardsSection from "../../components/CapitalAccountAssociatedCardsSection";
import ChargeCardRepayments from "../../components/ChargeCardRepayments";
import CapitalAccountBanners from "../../sections/CapitalAccountBanners";
import CapitalAccountHeader from "../../sections/CapitalAccountHeader";

const classes = {
  legendItem: "p-4 border-b border-grey-100 last-of-type:border-b-0",
};

type Props = {
  capitalAccountSummaryWithChargeCard: CapitalAccountSummaryWithChargeCard;
};

const ChargeCardViewV2: FC<Props> = ({ capitalAccountSummaryWithChargeCard }) => {
  const [
    isEditCapitalAccountAutoPayAccountModalOpen,
    setIsEditCapitalAccountAutoPayAccountModalOpen,
  ] = useState(false);

  const capitalRepaymentAmounts = capitalRepaymentAmountsQueryHooks.useData({
    capitalAccountGuid: capitalAccountSummaryWithChargeCard.guid,
    select: (data) => data.amounts,
  });

  const currentStatementAmountComplete = capitalRepaymentAmounts.find(
    (amount) => amount.type === "CurrentStatement"
  );
  const lastStatementAmountComplete = capitalRepaymentAmounts.find(
    (amount) => amount.type === "LastStatement"
  );
  const overdueAmountComplete = capitalRepaymentAmounts.find((amount) => amount.type === "Overdue");

  const nextAutoPayAmount = useNextCapitalAutoPayAmount(capitalAccountSummaryWithChargeCard.guid);

  // TODO(alex): HB-6272 Need to wait for new data to be returned by `CapitalRepaymentAmountApi`.
  const overdueBalanceDueBy = null;

  return (
    <>
      {isEditCapitalAccountAutoPayAccountModalOpen && (
        <EditCapitalAccountAutoPayAccountModal
          capitalAccountSummary={capitalAccountSummaryWithChargeCard}
          onClose={() => setIsEditCapitalAccountAutoPayAccountModalOpen(false)}
        />
      )}

      <CapitalAccountBanners capitalAccountSummary={capitalAccountSummaryWithChargeCard} />

      <CapitalAccountHeader capitalAccountSummary={capitalAccountSummaryWithChargeCard} />

      <DashboardPage.Section className="grid grid-cols-1 gap-14 @4xl:grid-cols-2">
        <div className="flex-1">
          <div className="mb-1 flex justify-between">
            <DashboardPage.Section.HeaderHeading className="font-medium">
              Available
            </DashboardPage.Section.HeaderHeading>
          </div>

          <MoneyAmountFraction
            className="mb-6 mt-2"
            variant="headline"
            numeratorInCents={capitalAccountSummaryWithChargeCard.available}
            denominatorInCents={capitalAccountSummaryWithChargeCard.details.limit}
          />

          <ChargeCardCapitalAccountBarChart
            capitalAccountSummaryWithChargeCard={capitalAccountSummaryWithChargeCard}
          />

          {(overdueAmountComplete ||
            lastStatementAmountComplete ||
            currentStatementAmountComplete) && (
            <div className="mt-6 rounded-lg border border-grey-200">
              {overdueAmountComplete && (
                <div className={classes.legendItem}>
                  <div className="mb-3 flex justify-between">
                    <div className="flex items-center gap-x-4">
                      <BarChart
                        height={12}
                        backgroundVariant="transparent"
                        className="w-3"
                        roundedCornerVariant="rounded-none"
                      >
                        <BarChart.Bar color="red-striped" widthPercentage={100} />
                      </BarChart>
                      <Span className="flex items-center gap-x-2 text-sm font-medium text-red-800">
                        Overdue
                        {overdueBalanceDueBy && (
                          <IconWithTooltip
                            tooltip={
                              <>
                                Overdue balances are due by{" "}
                                <Strong className="font-medium">{overdueBalanceDueBy}</Strong>.
                                Missing a daily repayment can result in associated cards being
                                frozen.
                              </>
                            }
                          />
                        )}
                        <Pill color="grey-light">
                          {capitalAccountSummaryWithChargeCard.details.apr * 100}% APR
                        </Pill>
                      </Span>
                    </div>

                    <MoneyAmount
                      cents={overdueAmountComplete.amount}
                      className="text-sm font-medium"
                    />
                  </div>

                  <div className="flex justify-between pl-7">
                    <Span className="text-sm text-red-800">Next overdue payment</Span>
                    <MoneyAmount
                      cents={overdueAmountComplete.nextRepaymentAmount}
                      className="text-sm font-medium"
                    />
                  </div>
                </div>
              )}

              {lastStatementAmountComplete && (
                <div className={classes.legendItem}>
                  <div className="mb-1 flex justify-between">
                    <div className="flex items-center gap-x-4">
                      <BarChart
                        height={12}
                        backgroundVariant="transparent"
                        className="w-3"
                        roundedCornerVariant="rounded-none"
                      >
                        <BarChart.Bar color="orange-striped" widthPercentage={100} />
                      </BarChart>
                      <Span className="flex items-center gap-x-2 text-sm font-medium text-grey-800">
                        Last statement
                        {lastStatementAmountComplete.nextRepaymentDate && (
                          <Pill
                            color="yellow-legacy"
                            iconLeft={({ sizeClassName }) => <Clock className={sizeClassName} />}
                          >
                            Due{" "}
                            {dayjs(lastStatementAmountComplete.nextRepaymentDate).format("MMM D")}
                          </Pill>
                        )}
                      </Span>
                    </div>

                    <MoneyAmount
                      cents={lastStatementAmountComplete.amount}
                      className="text-sm font-medium"
                    />
                  </div>

                  <div className="flex justify-between pl-7 pr-4">
                    <Span className="text-sm text-grey-600">
                      <ChargeCardRepaymentPeriodDateRangeByCapitalAccountGuid
                        capitalAccountGuid={capitalAccountSummaryWithChargeCard.guid}
                        statementPeriod="previous"
                      />
                    </Span>
                  </div>
                </div>
              )}

              {currentStatementAmountComplete && (
                <div className={classes.legendItem}>
                  <div className="mb-1 flex justify-between">
                    <div className="flex items-center gap-x-4">
                      <BarChart
                        height={12}
                        backgroundVariant="transparent"
                        className="w-3"
                        roundedCornerVariant="rounded-none"
                      >
                        <BarChart.Bar color="grey-striped" widthPercentage={100} />
                      </BarChart>
                      <Span className="flex items-center gap-x-2 text-sm font-medium text-grey-800">
                        Current statement
                        {currentStatementAmountComplete.nextRepaymentDate && (
                          <Pill
                            color="grey-light"
                            iconLeft={({ sizeClassName }) => <Clock className={sizeClassName} />}
                          >
                            Due{" "}
                            {dayjs(currentStatementAmountComplete.nextRepaymentDate).format(
                              "MMM D"
                            )}
                          </Pill>
                        )}
                      </Span>
                    </div>

                    <MoneyAmount
                      cents={currentStatementAmountComplete.amount}
                      className="text-sm font-medium"
                    />
                  </div>

                  <div className="flex justify-between pl-7 pr-4">
                    <Span className="text-sm text-grey-600">
                      <ChargeCardRepaymentPeriodDateRangeByCapitalAccountGuid
                        capitalAccountGuid={capitalAccountSummaryWithChargeCard.guid}
                        statementPeriod="current"
                      />
                    </Span>
                  </div>
                </div>
              )}
            </div>
          )}

          <VirtualButton
            className="mt-6 flex cursor-pointer rounded-lg border border-grey-200"
            onClick={() => setIsEditCapitalAccountAutoPayAccountModalOpen(true)}
          >
            <NextAutoPaymentHighlightItem
              className="px-6 py-4"
              nextAutoPayAmount={nextAutoPayAmount}
            />

            <Divider orientation="vertical" className="my-6" />

            <ChargeCardAutoPayBankAccountHighlightItemByCapitalAccountGuid
              capitalAccountGuid={capitalAccountSummaryWithChargeCard.guid}
              className="flex max-w-80 flex-col overflow-auto whitespace-nowrap px-6 py-4"
            />

            <CaretRight
              size={24}
              className="ml-auto mr-6 h-4 self-center text-grey-500"
              weight="light"
            />
          </VirtualButton>
        </div>

        <div className="flex-1">
          <DashboardPage.Section.HeaderHeading className="mb-1 font-medium">
            Details
          </DashboardPage.Section.HeaderHeading>

          <ChargeCardCapitalAccountMetadataListByCapitalAccountGuid
            capitalAccountGuid={capitalAccountSummaryWithChargeCard.guid}
          />
        </div>
      </DashboardPage.Section>

      <CapitalAccountAssociatedCardsSection
        capitalAccountSummaryWithChargeCard={capitalAccountSummaryWithChargeCard}
      />

      <DashboardPage.Section>
        <ChargeCardRepayments
          capitalAccountSummaryWithChargeCard={capitalAccountSummaryWithChargeCard}
        />
      </DashboardPage.Section>
    </>
  );
};

export default ChargeCardViewV2;
