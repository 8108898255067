import { FC } from "react";
import BarChart from "ui/data-visualization/BarChart";

import capitalRepaymentAmountsQueryHooks from "../queries/capitalRepaymentAmountsQueryHooks";
import { CapitalAccountSummaryWithChargeCard } from "../utils/isCapitalAccountSummaryWithChargeCard";

type Props = {
  capitalAccountSummaryWithChargeCard: CapitalAccountSummaryWithChargeCard;
};

const ChargeCardCapitalAccountBarChart: FC<Props> = ({ capitalAccountSummaryWithChargeCard }) => {
  const { limit } = capitalAccountSummaryWithChargeCard.details;

  const capitalRepaymentAmounts = capitalRepaymentAmountsQueryHooks.useData({
    capitalAccountGuid: capitalAccountSummaryWithChargeCard.guid,
    select: (data) => data.amounts,
  });

  const currentStatementAmountComplete = capitalRepaymentAmounts.find(
    (amount) => amount.type === "CurrentStatement"
  );
  const currentStatementAmount = currentStatementAmountComplete?.amount ?? 0;
  const lastStatementAmountComplete = capitalRepaymentAmounts.find(
    (amount) => amount.type === "LastStatement"
  );
  const lastStatementAmount = lastStatementAmountComplete?.amount ?? 0;
  const overdueAmountComplete = capitalRepaymentAmounts.find((amount) => amount.type === "Overdue");
  const overdueAmount = overdueAmountComplete?.amount ?? 0;

  const currentStatementPercentage = (currentStatementAmount / limit) * 100;
  const lastStatementPercentage = (lastStatementAmount / limit) * 100;
  const overduePercentage = (overdueAmount / limit) * 100;
  const availableAmountPercentage =
    100 - currentStatementPercentage - lastStatementPercentage - overduePercentage;

  return (
    <BarChart
      height={32}
      barHeight={16}
      backgroundVariant="transparent"
      border="grey-light"
      roundedCornerVariant="rounded-full"
    >
      {availableAmountPercentage > 0 && (
        <BarChart.Bar
          widthPercentage={availableAmountPercentage}
          minWidthPercentageWhenGreaterThanZero={1}
          color="green"
        />
      )}
      {overduePercentage > 0 && (
        <BarChart.Bar
          widthPercentage={overduePercentage}
          minWidthPercentageWhenGreaterThanZero={1}
          color="red-striped"
        />
      )}
      {lastStatementPercentage > 0 && (
        <BarChart.Bar
          widthPercentage={lastStatementPercentage}
          minWidthPercentageWhenGreaterThanZero={1}
          color="orange-striped"
        />
      )}
      {currentStatementPercentage > 0 && (
        <BarChart.Bar
          widthPercentage={currentStatementPercentage}
          minWidthPercentageWhenGreaterThanZero={1}
          color="grey-striped"
        />
      )}
    </BarChart>
  );
};

export default ChargeCardCapitalAccountBarChart;
