import { FC } from "react";
import { CapitalAccountSummaryWithChargeCard } from "resources/capital-accounts/utils/isCapitalAccountSummaryWithChargeCard";
import useChargeCardOffer from "resources/charge-cards/queries/useChargeCardOffer";

import getChargeCardRepaymentTermsCopy from "../utils/getChargeCardRepaymentTermsCopy";

type Props = {
  capitalAccountSummaryWithChargeCard: CapitalAccountSummaryWithChargeCard;
};

const ChargeCardOfferRepaymentPeriodContent: FC<Props> = ({
  capitalAccountSummaryWithChargeCard,
}) => {
  const chargeCardOffer = useChargeCardOffer(capitalAccountSummaryWithChargeCard.guid, {
    required: true,
  });
  return <>{getChargeCardRepaymentTermsCopy(chargeCardOffer.maxRepaymentDays)}</>;
};

export default ChargeCardOfferRepaymentPeriodContent;
