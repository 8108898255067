import {
  ArrowFatLinesDown,
  ArrowsClockwise,
  Cards,
  ChartLine,
  CurrencyCircleDollar,
} from "@phosphor-icons/react";
import { FC } from "react";
import CapitalAccountSummaryRep from "reps/CapitalAccountSummaryRep";
import checkIsCapitalAccountSummaryWithChargeCard, {
  CapitalAccountSummaryWithChargeCard,
} from "resources/capital-accounts/utils/isCapitalAccountSummaryWithChargeCard";
import useChargeCardOffer from "resources/charge-cards/queries/useChargeCardOffer";
import { checkIsCashCreditTerm } from "resources/charge-cards/utils/check-credit-terms";
import colors from "styles/colors";
import { Paragraph } from "ui/typography";
import { pluralize } from "utils/string";

import styles from "./HowItWorksBulletPoints.module.scss";

type ChargeCardOfferDetailsProps = {
  capitalAccountSummaryWithChargeCard: CapitalAccountSummaryWithChargeCard;
};

const HowItWorksChargeCardBulletPoints: FC<ChargeCardOfferDetailsProps> = ({
  capitalAccountSummaryWithChargeCard,
}) => {
  const chargeCardOffer = useChargeCardOffer(capitalAccountSummaryWithChargeCard.guid, {
    required: true,
  });

  return (
    <div className={styles.howItWorksItemContainer}>
      <div className={styles.howItWorksItem}>
        <div className={styles.howItWorksItemIcon}>
          <ChartLine size={20} color={colors.purple[500]} />
        </div>
        {checkIsCashCreditTerm(chargeCardOffer.creditTerms) ? (
          <Paragraph className="text-sm">
            Your limit is based on your transaction and balance history.
          </Paragraph>
        ) : (
          <Paragraph className="text-sm">
            Your limit is based on your current and historical debt, assets, and sales.
          </Paragraph>
        )}
      </div>
      <div className={styles.howItWorksItem}>
        <div className={styles.howItWorksItemIcon}>
          <Cards size={20} color={colors.purple[500]} />
        </div>
        <Paragraph className="text-sm">
          After activating this card offer, create Highbeam Cards and connect them to your card
          limit.
        </Paragraph>
      </div>
      <div className={styles.howItWorksItem}>
        <div className={styles.howItWorksItemIcon}>
          <CurrencyCircleDollar size={20} color={colors.purple[500]} />
        </div>
        {chargeCardOffer.maxCashback > 0 ? (
          <Paragraph className="text-sm">
            Earn up to <strong>{chargeCardOffer.maxCashback}% cash back</strong> across all approved
            vendors
          </Paragraph>
        ) : (
          <Paragraph className="text-sm">
            Get up to {pluralize(chargeCardOffer.maxRepaymentDays, "day")} interest-free days to
            manage your cash flow.
          </Paragraph>
        )}
      </div>
    </div>
  );
};

const HowItWorksCashAccessOnlyBulletPoints = () => (
  <div className={styles.howItWorksItemContainer}>
    <div className={styles.howItWorksItem}>
      <div className={styles.howItWorksItemIcon}>
        <ChartLine size={20} color={colors.purple[500]} />
      </div>
      <Paragraph className="text-sm">
        Your limit is based on your current and historical debt, assets, and sales
      </Paragraph>
    </div>
    <div className={styles.howItWorksItem}>
      <div className={styles.howItWorksItemIcon}>
        <ArrowFatLinesDown size={20} color={colors.purple[500]} />
      </div>
      <Paragraph className="text-sm">
        Draw down funds instantly and repay your line at any time
      </Paragraph>
    </div>
    <div className={styles.howItWorksItem}>
      <div className={styles.howItWorksItemIcon}>
        <ArrowsClockwise size={20} color={colors.purple[500]} />
      </div>
      <Paragraph className="text-sm">
        After every drawdown you’ll start repaying it with auto-pay
      </Paragraph>
    </div>
  </div>
);

type Props = {
  capitalAccountSummary: CapitalAccountSummaryRep.Complete;
};

const HowItWorksBulletPoints: FC<Props> = ({ capitalAccountSummary }) => {
  return (
    <>
      {checkIsCapitalAccountSummaryWithChargeCard(capitalAccountSummary) ? (
        <HowItWorksChargeCardBulletPoints
          capitalAccountSummaryWithChargeCard={capitalAccountSummary}
        />
      ) : (
        <HowItWorksCashAccessOnlyBulletPoints />
      )}
    </>
  );
};

export default HowItWorksBulletPoints;
