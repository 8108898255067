import { FC, ReactNode } from "react";
import ChargeCardAccountRep from "reps/ChargeCardAccountRep";
import BankAccountBalanceMoneyAmountByBankAccountGuid from "resources/bank-accounts/components/BankAccountBalanceMoneyAmountByBankAccountGuid";
import { BankAccountBarByGuid } from "resources/bank-accounts/components/BankAccountBar";
import chargeCardAccountQueryHooks from "resources/charge-cards/queries/chargeCardAccountQueryHooks";
import HighlightItemV2, { HighlightItemV2Loading } from "ui/data-display/HighlightItemV2";
import { Span } from "ui/typography";

type Props = {
  chargeCardAccount: ChargeCardAccountRep.Complete;
  className?: string;
};

const ChargeCardAutoPayBankAccountHighlightItem: FC<Props> = ({ chargeCardAccount, className }) => {
  return (
    <HighlightItemV2 className={className}>
      <HighlightItemV2.Heading>Auto-pay account</HighlightItemV2.Heading>

      <HighlightItemV2.Body>
        <BankAccountBarByGuid
          bankAccountGuid={chargeCardAccount.repaymentBankAccountGuid}
          className="overflow-hidden overflow-ellipsis"
        />
      </HighlightItemV2.Body>

      <HighlightItemV2.Footer>
        <Span className="text-xs text-grey-600">
          Balance:{" "}
          <BankAccountBalanceMoneyAmountByBankAccountGuid
            bankAccountGuid={chargeCardAccount.repaymentBankAccountGuid}
            size={12}
            weight="regular"
            centsTextWeight="regular"
          />
        </Span>
      </HighlightItemV2.Footer>
    </HighlightItemV2>
  );
};

export default ChargeCardAutoPayBankAccountHighlightItem;

// Wrappers

type ChargeCardAutoPayBankAccountHighlightItemByCapitalAccountGuidProps = {
  capitalAccountGuid: string;
  className?: string;
  notFoundFallback?: ReactNode;
};

export const ChargeCardAutoPayBankAccountHighlightItemByCapitalAccountGuid: FC<
  ChargeCardAutoPayBankAccountHighlightItemByCapitalAccountGuidProps
> = ({ capitalAccountGuid, notFoundFallback = null, className }) => {
  const { data: chargeCardAccount, isLoading } = chargeCardAccountQueryHooks.useQuery({
    capitalAccountGuid,
  });

  if (isLoading) {
    return <HighlightItemV2Loading className={className} />;
  }

  if (!chargeCardAccount) {
    return <>{notFoundFallback}</>;
  }

  return (
    <ChargeCardAutoPayBankAccountHighlightItem
      chargeCardAccount={chargeCardAccount}
      className={className}
    />
  );
};
