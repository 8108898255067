import IconWithTooltip from "components/common/IconWithTooltip";
import { FC } from "react";
import { CapitalAccountSummaryWithChargeCard } from "resources/capital-accounts/utils/isCapitalAccountSummaryWithChargeCard";
import ChargeCardOfferRepaymentPeriodContent from "resources/charge-cards/components/ChargeCardOfferRepaymentPeriodContent";
import StatementCycleExplanation from "resources/charge-cards/components/StatementCycleExplanation";
import useChargeCardOffer from "resources/charge-cards/queries/useChargeCardOffer";
import Divider from "ui/data-display/Divider";
import OfferDetails from "ui/data-display/OfferDetails";

import ChargeCardCashBackInfoIconWithTooltip from "../ChargeCardCashBackInfoIconWithTooltip";

type Props = {
  capitalAccountSummaryWithChargeCard: CapitalAccountSummaryWithChargeCard;
};

const CapitalAccountCardChargeCardOfferDetailsContent: FC<Props> = ({
  capitalAccountSummaryWithChargeCard,
}) => {
  const chargeCardOffer = useChargeCardOffer(capitalAccountSummaryWithChargeCard.guid, {
    required: true,
  });

  return (
    <>
      {chargeCardOffer.maxCashback > 0 && (
        <>
          <OfferDetails.Item
            label={
              <>
                Cash back
                <ChargeCardCashBackInfoIconWithTooltip chargeCardOffer={chargeCardOffer} />
              </>
            }
            value={`Up to ${chargeCardOffer.maxCashback}%`}
          />

          <Divider orientation="vertical" />
        </>
      )}

      <OfferDetails.Item
        label={
          <>
            Repayment period
            <IconWithTooltip
              color="dark"
              tooltip={<StatementCycleExplanation creditTerms={chargeCardOffer.creditTerms} />}
            />
          </>
        }
        value={
          <ChargeCardOfferRepaymentPeriodContent
            capitalAccountSummaryWithChargeCard={capitalAccountSummaryWithChargeCard}
          />
        }
      />
    </>
  );
};

export default CapitalAccountCardChargeCardOfferDetailsContent;
