import { CalendarBlank, Timer } from "@phosphor-icons/react";
import MultiStep from "layouts/MultiStep";
import { FC } from "react";
import { useNavigate } from "react-router-dom";
import CapitalAccountSummaryRep from "reps/CapitalAccountSummaryRep";
import BankAccountsDropdown from "resources/bank-accounts/components/BankAccountsDropdown";
import { useOpenBankAccountRequired } from "resources/bank-accounts/queries/bankAccountQueryHooks";
import bankAccountsQueryHooks from "resources/bank-accounts/queries/bankAccountsQueryHooks";
import usePrimaryBankAccount from "resources/bank-accounts/queries/usePrimaryBankAccount";
import mapTransferOption from "resources/bank-accounts/utils/mapTransferOption";
import BackupAutoPayAccountsHelper from "resources/capital-accounts/components/BackupAutoPayAccountsHelper";
import useCapitalAccountRepaymentBankAccount from "resources/capital-accounts/hooks/useCapitalAccountRepaymentBankAccount";
import useCapitalBackupAutoPayAccounts from "resources/capital-accounts/hooks/useCapitalBackupAutoPayAccounts";
import checkIsCapitalAccountSummaryWithChargeCard, {
  CapitalAccountSummaryWithChargeCard,
} from "resources/capital-accounts/utils/isCapitalAccountSummaryWithChargeCard";
import ChargeCardExampleStatementPeriod from "resources/charge-cards/components/ChargeCardExampleStatementPeriod";
import StatementCycleExplanation from "resources/charge-cards/components/StatementCycleExplanation";
import useChargeCardOffer from "resources/charge-cards/queries/useChargeCardOffer";
import Banner from "ui/data-display/Banner";
import Divider from "ui/data-display/Divider";
import MoneyAmount from "ui/data-display/money/MoneyAmount";
import Dropdown from "ui/inputs/Dropdown";
import { Paragraph, Span } from "ui/typography";
import Text from "ui/typography/Text";

import ReviewLineOfferBackButton from "../../components/ReviewLineOfferBackButton";
import { useReviewLineOfferContext } from "../../context/ReviewLineOfferProvider";

import AutoPayScheduleSteps from "./AutoPayScheduleSteps";

const AUTO_PAY_FORM_ID = "auto-pay-form";

type AutoPayChargeCardDetailsProps = {
  capitalAccountSummaryWithChargeCard: CapitalAccountSummaryWithChargeCard;
};

const AutoPayChargeCardDetails: FC<AutoPayChargeCardDetailsProps> = ({
  capitalAccountSummaryWithChargeCard,
}) => {
  const repaymentAccount = useCapitalAccountRepaymentBankAccount({
    capitalAccountSummary: capitalAccountSummaryWithChargeCard,
  });

  const { openBankAccounts, plaidBankAccounts } = useCapitalBackupAutoPayAccounts();

  const chargeCardOffer = useChargeCardOffer(capitalAccountSummaryWithChargeCard.guid, {
    required: true,
  });
  const targetRepaymentDays = capitalAccountSummaryWithChargeCard.details.targetRepaymentDays;

  return (
    <div className="flex flex-col gap-y-6">
      <Paragraph className="text-md text-grey-600">
        <StatementCycleExplanation creditTerms={chargeCardOffer.creditTerms} />
      </Paragraph>

      <div>
        <BankAccountsDropdown
          label="Auto-pay account"
          value={
            openBankAccounts.find((option) => {
              return option.guid === repaymentAccount.guid;
            }) ?? openBankAccounts[0]
          }
          options={openBankAccounts}
          disabled
        />

        <BackupAutoPayAccountsHelper
          className="mt-4"
          openBankAccounts={openBankAccounts}
          plaidBankAccounts={plaidBankAccounts}
        />
      </div>

      <div className="flex flex-col gap-6 rounded-lg bg-grey-50 p-6">
        <Paragraph className="text-sm font-medium">Example statement period:</Paragraph>
        <ChargeCardExampleStatementPeriod chargeCardCreditTerm={chargeCardOffer.creditTerms} />
      </div>
      <Banner
        padding="normal"
        color="purple"
        icon={<Timer size={24} />}
        title="Overdue balances"
        paragraph={
          <>
            After the due date, any overdue balance will accrue interest daily at{" "}
            {capitalAccountSummaryWithChargeCard.details.apr * 100}% APR and Highbeam will
            auto-debit from your auto-pay accounts over the following{" "}
            {targetRepaymentDays === 1 ? "days" : `${targetRepaymentDays} days`} until the balance
            is repaid in full.
          </>
        }
      />
    </div>
  );
};

type AutoPayCashAccessOnlyDetailsProps = {
  capitalAccountSummaryWithCashAccessOnly: CapitalAccountSummaryRep.CashAccessOnlyComplete;
};

const AutoPayCashAccessOnlyDetails: FC<AutoPayCashAccessOnlyDetailsProps> = ({
  capitalAccountSummaryWithCashAccessOnly,
}) => {
  const primaryAccount = usePrimaryBankAccount({ required: true });
  const repaymentAccount = useOpenBankAccountRequired(primaryAccount.guid);
  const accountOptions = bankAccountsQueryHooks.useData({
    status: "open",
    select: (bankAccounts) => bankAccounts.map(mapTransferOption),
  });

  const targetRepaymentDays = capitalAccountSummaryWithCashAccessOnly.details.targetRepaymentDays;

  return (
    <div className="flex flex-col gap-y-6">
      <Paragraph className="text-md text-grey-900">
        Each drawdown will be repaid in equal daily installments over {targetRepaymentDays} days.
      </Paragraph>
      <div className="rounded-lg bg-grey-50">
        <div className="flex items-center justify-between px-6 py-4">
          <div className="flex items-center gap-x-2">
            <CalendarBlank className="size-5 text-grey-800" />
            <Span className="text-sm text-grey-800">Example auto-pay schedule</Span>
          </div>
          <Text className="text-sm text-grey-800">Daily payment</Text>
        </div>

        <Divider className="my-0" />

        <div className="flex items-center justify-between px-8 pb-6 pt-4">
          <AutoPayScheduleSteps capitalAccountSummary={capitalAccountSummaryWithCashAccessOnly} />

          <Text size={14}>
            <MoneyAmount
              cents={capitalAccountSummaryWithCashAccessOnly.details.limit / targetRepaymentDays}
              currency={"USD"}
              weight="medium"
            />
            {" / "}
            day
          </Text>
        </div>
      </div>
      <Dropdown
        label="Auto-pay account"
        value={
          accountOptions.find((option) => {
            return option.guid === repaymentAccount.guid;
          }) ?? accountOptions[0]
        }
        options={accountOptions}
        disabled
      />
    </div>
  );
};

type Props = {
  capitalAccountSummary: CapitalAccountSummaryRep.Complete;
};

const AutoPayView: FC<Props> = ({ capitalAccountSummary }) => {
  const navigate = useNavigate();
  const { nextPathname } = useReviewLineOfferContext();

  const handleSubmit: React.FormEventHandler<HTMLFormElement> = (e) => {
    e.preventDefault();
    if (nextPathname) {
      navigate(nextPathname);
    }
  };

  return (
    <MultiStep.Form id={AUTO_PAY_FORM_ID} onSubmit={handleSubmit}>
      <MultiStep.Section>
        <MultiStep.Section.Header>
          <MultiStep.Section.Header.Heading>Auto-pay</MultiStep.Section.Header.Heading>
        </MultiStep.Section.Header>
      </MultiStep.Section>

      <MultiStep.Section>
        {checkIsCapitalAccountSummaryWithChargeCard(capitalAccountSummary) ? (
          <AutoPayChargeCardDetails capitalAccountSummaryWithChargeCard={capitalAccountSummary} />
        ) : (
          <AutoPayCashAccessOnlyDetails
            capitalAccountSummaryWithCashAccessOnly={capitalAccountSummary}
          />
        )}
      </MultiStep.Section>

      <MultiStep.Controls>
        <ReviewLineOfferBackButton />
        <MultiStep.Controls.NextButton form={AUTO_PAY_FORM_ID}>Next</MultiStep.Controls.NextButton>
      </MultiStep.Controls>
    </MultiStep.Form>
  );
};

export default AutoPayView;
