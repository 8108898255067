import IconWithTooltip from "components/common/IconWithTooltip";
import { FC } from "react";
import ChargeCardOfferRep from "reps/ChargeCardOfferRep";
import { Paragraph } from "ui/typography";

type Props = {
  chargeCardOffer: ChargeCardOfferRep.Complete;
};

const ChargeCardCashBackInfoIconWithTooltip: FC<Props> = ({ chargeCardOffer }) => {
  return (
    <IconWithTooltip
      color="dark"
      tooltip={
        <Paragraph>
          {chargeCardOffer.maxCashback}% on all qualifying ad spend, and{" "}
          {chargeCardOffer.minCashback}% all other spend. Cash back is paid directly to your
          Highbeam bank account.
        </Paragraph>
      }
    />
  );
};

export default ChargeCardCashBackInfoIconWithTooltip;
